import { Observable } from "rxjs";
import { Joint } from "src/app/domain/models/joint";
import { JointChange } from "src/app/domain/models/joint-change";

export abstract class IJointService {
    abstract getAllJoints(): Observable<Joint[]>;
    abstract getJointDetails(jointId: string): Observable<Joint>;
    abstract getJointChanges(jointId: string): Observable<JointChange[]>;
    abstract getJointImages(joint: Joint): Observable<string[]>;
    abstract getJointDrawings(joint: Joint): Observable<string[]>;
    abstract deleteJointImage(image: string): Observable<boolean>;
    abstract deleteJointDrawing(drawing: string): Observable<boolean>;
}