export const environment = {
    appVersion: require('../../package.json').version,
    production: false,
    verticalBusiness: 'oilandgas',
    api: 'https://dev.sat.oilandgas.web.api.texascontrols.com',
    apiUpload: 'https://dev.filestorage.api.texascontrols.com',
    flangeDocumentsUrl: 'https://jims.texascontrols.com/files/TXC_JIMS/',
    firebase: {
        apiKey: "AIzaSyCp75la5ZatA-G2tmc0o9_X2MVBmgG53EQ",
        authDomain: "texas-controls.firebaseapp.com",
        projectId: "texas-controls",
        storageBucket: "texas-controls.appspot.com",
        messagingSenderId: "795062308999",
        appId: "1:795062308999:web:a0457aca03025afbe92822"
    },
    useEmulators: false,
    sentry: {
        dsn: "https://3602438267e8c584147e73bd88e35784@o4507254615310336.ingest.de.sentry.io/4507338282238032"
    }
};